<form [formGroup]="formInvitado" (ngSubmit)="onSubmit()" class="form-invitado flex flex-col w-[40%]">
  <div>
    <small class="font-semibold mb-2">Si se requiere factura de la compra, se necesita <a class="text-[#0ebde5]"
        [routerLink]="['/login']">registrar o iniciar
        sesión</a>.</small>
  </div>
  <mat-form-field appearance="fill">
    <mat-label>Nombre</mat-label>
    <input matInput formControlName="nombre" type="text">
    <mat-error *ngIf="formInvitado.get('nombre')?.invalid && formInvitado.get('nombre')?.touched">
      <small *ngIf="formInvitado.get('nombre')?.errors?.['required']">El nombre es requerido.</small>
      <small *ngIf="formInvitado.get('nombre')?.errors?.['minlength']">El nombre debe tener al menos 3
        caracteres.</small>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email" type="email">
    <mat-error *ngIf="formInvitado.get('email')?.invalid && formInvitado.get('email')?.touched">
      <small *ngIf="formInvitado.get('email')?.errors?.['required']">El email es requerido.</small>
      <small *ngIf="formInvitado.get('email')?.errors?.['email']">El email no es válido.</small>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Teléfono</mat-label>
    <input matInput formControlName="phone" type="text">
    <mat-error *ngIf="formInvitado.get('phone')?.invalid && formInvitado.get('phone')?.touched">
      <small *ngIf="formInvitado.get('phone')?.errors?.['required']">El teléfono es requerido.</small>
      <small
        *ngIf="formInvitado.get('phone')?.errors?.['minlength'] || formInvitado.get('phone')?.errors?.['maxlength']">El
        teléfono debe tener 10
        caracteres.</small>
    </mat-error>
  </mat-form-field>

  <div class="flex items-center w-full justify-between mb-4">
    <mat-checkbox formControlName="envio">Si requiere envío, marque la casilla</mat-checkbox>
    <div class="w-[55%]">
      <small class="text-black font-semibold">En caso de no requerir envío, se tiene que pasar a recoger el
        pedido.</small>
      <br>
      <small
        class="paso-por-el text-[#0ebde5] font-500 bg-white rounded-full px-[10px] py-[4px] border-[1px] border-[#0ebde5]">UNICAMENTE
        EN
        SUCURSAL
        CHIHUAHUA</small>
      <small>
        <label for="me3" class="txt">
          <p class="text-black font-semibold mb-1 mt-1">
            Se puede recoger apartir de 2 horas después de realizada la compra.
            De NO ir por el producto se tendrá que cobrar el envío.
          </p>
          <div class="direccion-suc flex items-center">
            <p class="item-description mr-2" style="font-size: 1em; margin-block-start: 0em; margin-block-end: 0em;">C.
              Washington No. 3701.
              <br>
              Int.Edificio 48
              <br>
              Parque Ind. Las Américas
              <br>
              México, Chihuahua Chih.
              <br>
              31114
            </p>
            <button class="continuar-checkout"><a class="text-white hover:text-[#0ebde5]"
                href="https://maps.app.goo.gl/W9KS7FRd9WS9mKtP8" target="_blank">¿Cómo
                llegar?</a></button>
          </div>
        </label>
      </small>
    </div>
  </div>

  <div *ngIf="isEnvio" class="flex flex-col">
    <mat-form-field appearance="fill">
      <mat-label>Dirección</mat-label>
      <input matInput formControlName="direccion" type="text">
      <mat-error *ngIf="formInvitado.get('direccion')?.invalid && formInvitado.get('direccion')?.touched">
        <small *ngIf="formInvitado.get('direccion')?.errors?.['required']">La dirección es requerida.</small>
        <small *ngIf="formInvitado.get('direccion')?.errors?.['minlength']">La dirección debe tener al menos 10
          caracteres.</small>
      </mat-error>
    </mat-form-field>

    <small *ngIf="isCpNoEncontrado" class="text-red-600">El código postal no se encontró. Intenta de nuevo.</small>
    <mat-form-field appearance="fill">
      <mat-label>Código Postal</mat-label>
      <input matInput formControlName="cp" type="text" (change)="getCP()">
      <mat-error *ngIf="formInvitado.get('cp')?.invalid && formInvitado.get('cp')?.touched">
        <small *ngIf="formInvitado.get('cp')?.errors?.['required']">El código postal es requerido.</small>
        <small *ngIf="formInvitado.get('cp')?.errors?.['minlength']">El código postal debe tener al menos 5
          caracteres.</small>
      </mat-error>
    </mat-form-field>

    @if (isCode) {
    <small class="text-black font-semibold">En caso de no encontrar la colonia deseada, ingresarla
      manualmente.</small>
    <mat-form-field>
      <input type="text" placeholder="Selecciona tu colonia" aria-label="Number" matInput formControlName="colonia"
        [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of options" [value]="option.nombre_colonia">
          {{option.nombre_colonia}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="formInvitado.get('colonia')?.invalid && formInvitado.get('colonia')?.touched">
        <small *ngIf="formInvitado.get('colonia')?.errors?.['required']">La colonia es requerida.</small>
        <small *ngIf="formInvitado.get('colonia')?.errors?.['minlength']">La colonia debe tener al menos 5
          caracteres.</small>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Ciudad</mat-label>
      <input matInput formControlName="ciudad" type="text">
      <mat-error *ngIf="formInvitado.get('ciudad')?.invalid && formInvitado.get('ciudad')?.touched">
        <small *ngIf="formInvitado.get('ciudad')?.errors?.['required']">La ciudad es requerida.</small>
        <small *ngIf="formInvitado.get('ciudad')?.errors?.['minlength']">La ciudad debe tener al menos 5
          caracteres.</small>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Estado</mat-label>
      <input matInput formControlName="estado" type="text">
      <mat-error *ngIf="formInvitado.get('estado')?.invalid && formInvitado.get('estado')?.touched">
        <small *ngIf="formInvitado.get('estado')?.errors?.['required']">El estado es requerido.</small>
        <small *ngIf="formInvitado.get('estado')?.errors?.['minlength']">El estado debe tener al menos 5
          caracteres.</small>
      </mat-error>
    </mat-form-field>
    }
  </div>

  <button mat-raised-button color="primary" type="submit" [disabled]="formInvitado.invalid">Enviar</button>
</form>