<div id="direccion" style=" margin-top: 100px;" class="direccion" *ngIf="medioErrorAlv == 1">
  <ion-card>
    <div class="part1">
      <ion-card-title class="titulosSecciones" style="color: #c70000;">Tu cuenta no tiene dirección</ion-card-title>
      <p class="subtitulosSecciones">Favor de agregar una direccion para continuar.</p>
    </div>

    <div class="part4">
      <button class="btn" [routerLink]="['/perfil','0']" routerLinkActive="router-link-active">Ir a mi cuenta</button>
    </div>
  </ion-card>
</div>

<section class="check-step-cont" style=" margin-top: 100px;" *ngIf="medioErrorAlv == 0">
  @if (isInvitado && formInvitado == null) {
  <app-form-invitado (formSubmitted)="handleFormSubmitted($event)"
    class="form-invitado m-[50px] w-full flex justify-center"></app-form-invitado>
  } @else if (!isInvitado) {
  <div class="step-1-cont" id="direccion">
    <h3 class="titles-steper">1.- DIRECCIONES</h3>
    <span style="color: #ff5c6a; font-weight: bold; font-size: 1.3em;">Da click en tu dirección de envío.</span>
    <div class="direct-cont" *ngFor="let direcciones of direccion; let i = index;">
      <!--<div class="inpa-cont-dir">
        
      </div>-->
      <input class="option-input radio" type="radio" id="radio{{+i}}" name="radioDireccion2"
        (change)="direccionIsChecked(direcciones.id_address)" value="{{i}}" [(ngModel)]="radioDireccion">
      <label for="radio{{+i}}" class="dir-data-cont">
        <div class="roxi-cont-dir">
          <!-- <div class="col-data-dir">
            <span class="hedin-data-txt">Nombre cliente</span>
            <span class="direc">{{direcciones.firstname}} {{direcciones.lastname}}</span>
          </div> -->
          <!-- <div class="col-data-dir">
            <span class="hedin-data-txt">Número teléfonico</span>
            <span class="direc">{{direcciones.phone}}</span>
          </div> -->
        </div>
        <div class="roxi-cont-dir">
          <div class="col-data-dir">
            <span class="hedin-data-txt">Dirección</span>
            <span class="direc">{{direcciones.address1}}</span>
          </div>
          <div class="col-data-dir">
            <span class="hedin-data-txt">Colonia</span>
            <span class="direc">{{direcciones.colony}}</span>
          </div>
        </div>

        <div class="roxi-cont-dir">
          <div class="col-data-dir">
            <span class="hedin-data-txt">Código postal</span>
            <span class="direc">{{direcciones.postcode}}</span>
          </div>
          <div class="col-data-dir">
            <span class="hedin-data-txt">Ciudad</span>
            <span class="direc">{{direcciones.city}}</span>
          </div>
        </div>

        <div class="roxi-cont-dir">
          <div class="col-data-dir">
            <span class="hedin-data-txt">Estado</span>
            <span class="direc">{{getNombreEstado(direcciones.id_state)}}</span>
          </div>
          <div class="col-data-dir">
            <span class="hedin-data-txt">País</span>
            <span class="direc">México</span>
          </div>


        </div>



      </label>
    </div>


    <!-- <div class="direct-cont" >
    <button>+</button>  
    </div> -->
    <div class="divbotones">
      <button class="continuar-checkout" [routerLink]="['/direcciones']" routerLinkActive="router-link-active">Nueva
        Dirección</button>
      <button class="continuar-checkout" (click)="confirmarDireccion()">Continuar</button>

    </div>

  </div>
  }

  <div class="step-2-cont" id="envio" style="display: none;">
    <h3 class="titles-steper">2.- METODO DE ENVÍO</h3>
    <span style="color: #ff5c6a; font-weight: 100; font-size: 1.1em;">Selecciona el metodo de envío.</span>
    <div class="envio-cont">
      <div class="opt-cont" style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
        <input type="radio" class="option-input radio" id="me1" name="radioEnvio" value="1" [(ngModel)]="radioEnvio"
          (change)="envioPaqueteExpress()" style="width: 1.2em; height: 1.2em;">
        <label for="me1" class="txt">
          <img src="assets/img/paqueteexpress.webp" alt="" style="width: 12em;">
          <span class="titl2" style="color:black!important; font-size: 1em;font-weight: 100; padding-left: 1em;">Los
            tiempos de entrega son estimados de 5 a 6 días hábiles.(No aplica para temporadas altas, en caso de
            cualquier duda consultar en términos de servicio).</span>
          <span class="titl3" style="color: #1d4a85 !important; font-weight: 500; font-size: 1.1em;"
            *ngIf="grandTotal<2000">{{paqueteExpress | currency: 'MX $'}}</span>
          <span style="color: #1d4a85 !important; font-weight: 500; font-size: 1.1em;" *ngIf="grandTotal>2000"
            class="titl3">¡Gratis!</span>
        </label>
      </div>
      <div class="opt-cont" *ngIf="grandTotal<2000"
        style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
        <input type="radio" class="option-input radio" id="me2" name="radioEnvio" value="2" [(ngModel)]="radioEnvio"
          (change)="envioEstafeta()" style="width: 1.2em; height: 1.2em;">
        <label for="me2" class="txt">
          <img src="assets/img/estafeta.webp" alt="" style="width: 12em;">
          <span style="color:black!important; font-size: 1em;font-weight: 100; padding-left: 1em;" class="titl2">Los
            tiempos de entrega son estimados de 5 a 6 días hábiles.(No aplica para temporadas altas, en caso de
            cualquier duda consultar en términos de servicio).</span>
          <span style="color: #ba3532!important; font-weight: 500; font-size: 1.1em;" class="titl3"
            *ngIf="grandTotal<2000">{{estafeta | currency: 'MX $'}}</span>
          <span style="color: #ba3532!important; font-weight: 500; font-size: 1.1em;" *ngIf="grandTotal>2000"
            class="titl3">¡Gratis!</span>
        </label>
      </div>

      @if (!isInvitado) {
      <div class="opt-cont" style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
        <input type="radio" class="option-input radio" id="me3" name="radioEnvio" value="3" [(ngModel)]="radioEnvio"
          (change)="envioRecoge()" style="width: 1.2em; height: 1.2em;">
        <label for="me3" class="txt">
          <p class="item-price"
            style="text-align: start; color: #000 !important; font-weight: 600 !important; font-size: .9em !important; margin-block-start: 0em; margin-block-end: 0em;">
            Se puede recoger apartir de 2 horas después de realizada la compra.</p>
          <p class="item-price"
            style="text-align: start; color: #000 !important; font-weight: 600 !important; font-size: .9em !important; margin-block-start: 0em; margin-block-end: 0em;">
            De NO ir por el producto se tendrá que cobrar el envío.</p>
          <p class="item-title"
            style="text-align: start; color: #000 !important; font-weight: 600 !important; font-size: .9em !important; margin-block-start: 0em; margin-block-end: 0em;">
            Paso por él, ÚNICAMENTE RECOGIENDO PAQUETE EN SUCURSAL Chihuahua</p>
          <p class="item-description" style="font-size: 1em; margin-block-start: 0em; margin-block-end: 0em;">C.
            Washington No. 3701. Int.</p>
          <p class="item-description" style="font-size: 1em; margin-block-start: 0em; margin-block-end: 0em;">Edificio
            48</p>
          <p class="item-description" style="font-size: 1em; margin-block-start: 0em; margin-block-end: 0em;">Parque
            Ind. Las Américas</p>
          <p class="item-description" style="font-size: 1em; margin-block-start: 0em; margin-block-end: 0em;">México,
            Chihuahua Chih.</p>
          <p class="item-description" style="font-size: 1em; margin-block-start: 0em; margin-block-end: 0em;">31114</p>
        </label>
      </div>
      }
    </div>
    <button class="back-checkout" (click)="regresarEnvio()">Regresar</button>
    <button class="continuar-checkout" (click)="continuarEnvio()">Continuar</button>
  </div>

  <div class="step-3-cont" id="pago" style="display: none;">
    <h3 class="titles-steper">3.- PAGO</h3>
    <div class="pago-cont min-w-full">
      <div class="terms-cont">
        <mat-slide-toggle name="checkacepto" [(ngModel)]="facturame2" (change)="abrirModalCorreo()"></mat-slide-toggle>
        <span class="txt-terms">
          Estoy de acuerdo con los
          <a style="cursor: pointer;" (click)="openModal2()">términos del servicio</a>
          y los
          <a style="cursor: pointer;" (click)="openModal()">términos de revocación</a>
          y los acepto sin reservas
        </span>
      </div>
      <span class="debestc" *ngIf="facturame2 == false">Debes aceptar términos y condiciones<br>para proceder con el
        pago.</span>
      <div class="suma-total">
        <span class="txt-mon">Total a pagar:</span>
        <span class="mon">${{grandTotal | number: '1.2-2'}}</span>
      </div>
      <div class="facturas-cont" *ngIf="facturame2">
        @if (!isInvitado) {
        <div class="txt-cont-facturas">
          <span class="txt-facts">¿Desea Facturar?</span>
          <mat-slide-toggle name="checkacepto" (change)="facturado()"></mat-slide-toggle>
        </div>
        }
        <div *ngIf="facturame">
          <div class="txt">
            <div class="compra">
              <select class="inps" [(ngModel)]="usocfdi" style="color: black;background-color: white;" value="G00">
                <option value="G01">G01 - Adquisición de mercancías</option>
                <option value="G02">G02 - Devoluciones, descuentos o bonificaciones</option>
                <option value="G03">G03 - Gastos en general</option>
                <option value="I02">I02 - Mobiliario y equipo de oficina por inversiones</option>
                <option value="I04">I04 - Equipo de cómputo y accesorios</option>
                <option value="I08">I08 - Otra maquinaria y equipo</option>
                <option value="P01">P01 - Por definir</option>
              </select>
              <input class="inps" type="text" [(ngModel)]="razonSocial" name="razonSocial" placeholder="RAZON SOCIAL">
              <input class="inps" type="text" name="rfc" [(ngModel)]="rfc" placeholder="RFC">
              <input class="inps" type="text" name="codigoPostal" [(ngModel)]="codigoPostal"
                placeholder="CODIGO POSTAL">
              <input class="inps" type="text" name="DirFiscal" [(ngModel)]="DirFiscal" placeholder="DIRECCIÓN FISCAL">
              <input class="inps" type="text" name="estadoFactura" [(ngModel)]="estadoFactura" placeholder="Estado">
              <input class="inps" type="text" name="ciudadFactura" [(ngModel)]="ciudadFactura" placeholder="Ciudad">
              <input class="inps" type="text" name="coloniaFactura" [(ngModel)]="coloniaFactura" placeholder="Colonia">
              <!-- <input class="inps" type="text" name="FormaPago" [(ngModel)]="metodoPago"  placeholder="Forma de Pago"> -->
              <select class="inps" [(ngModel)]="metodinPago" style="color: black;background-color: white;" value="G00">
                <option value="00">METODO DE PAGO</option>
                <option value="Transferencia">TRANSFERENCIA</option>
                <option value="Webpay">WEBPAY</option>
                <option value="Paypal">PAYPAL</option>
                <option value="Oxxo">OXXO</option>
              </select>
              <select class="inps" [(ngModel)]="regimenfiscal" style="color: black;background-color: white;"
                value="600">
                <option value="601">601 - General de Ley Personas Morales</option>
                <option value="603">603 - Personas Morales con Fines no Lucrativos</option>
                <option value="605">605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                <option value="606">606 - Arrendamiento</option>
                <option value="607">607 - Régimen de Enajenación o Adquisición de Bienes</option>
                <option value="608">608 - Demás ingresos</option>
                <option value="610">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                <option value="611">611 - Ingresos por Dividendos (socios y accionistas)</option>
                <option value="612">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
                <option value="614">614 - Ingresos por intereses</option>
                <option value="615">615 - Régimen de los ingresos por obtención de premios</option>
                <option value="616">616 - Sin obligaciones fiscales</option>
                <option value="620">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos
                </option>
                <option value="621">621 - Incorporación Fiscal</option>
                <option value="622">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                <option value="623">623 - Opcional para Grupos de Sociedades</option>
                <option value="624">624 - Coordinados</option>
                <option value="625">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas
                  Tecnológicas</option>
                <option value="626">626 - Régimen Simplificado de Confianza</option>
              </select>

              <!-- <input class="inps" type="text" name="UsoCFDI" [(ngModel)]="UsoCFDI" placeholder="Uso de CFDI"> -->
              <p>Guardar Datos para futuras facturas <button (click)="guardaDatosFactura()"
                  style="background-color: #ff5c6a; color: #fff; font-size: 1.5em; width: 1.5em; height: 1.5em; margin-left: .5em; border-radius: 1em;">+</button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="cont-opts-acord" *ngIf="facturame2">

        <div class="opt-acord relative">
          <div class="up">
            <input id="MPagoR" type="radio" name="radioPagos" value="21" (change)="pagarConMercadoP()">
            <label for="MPagoR">
              Pagar con Mercado Pago
            </label>
            <div class="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
              Nuevo
            </div>
          </div>
          <div style="width: 100%;" *ngIf="metodoPago == 'Mercado Pago' && facturame2 || mercadoWasOpen">
            <div class="part4 w-full flex p-4 flex-col">

              <!-- TODO: Descomentar cuandop mercado pago vuelva a ser envevido -->

              <!-- @if (MP_isLoading) {
              <app-spinner class="self-center"> </app-spinner>
              }

              <div *ngIf="!MP_isCompletePayment">
                <div id="paymentBrick_container"> </div>
              </div>
              <div id="statusScreenBrick_container"></div> -->

              <button [disabled]=" this.respuestaOV !== null || metodoPago != 'Mercado Pago'" class="compra-ya"
                id="comprame" (click)="relizarCompra()">Pagar</button>

            </div>
          </div>
        </div>

        @if(grandTotal >= 300 && grandTotal <= 24800){ <div class="opt-acord relative">
          <div class="up">
            <input id="KueskiR" type="radio" name="radioPagos" value=5 (change)="pagarConKueski()">
            <label for="KueskiR">
              Pagar con Kueski Pay
            </label>
          </div>
          <div style="width: 100%;" *ngIf="metodoPago == 'Kueski Pay' && facturame2">
            <div class="part4" style="width: 100%; display: flex; justify-content: center; padding: 1em 0em;">
              <button [disabled]=" this.respuestaOV !== null || metodoPago != 'Kueski Pay'" class="compra-ya"
                id="comprame" (click)="relizarCompra()">Pagar 😊</button>
            </div>
          </div>
      </div>
      }

      <div class="opt-acord">
        <div class="up">
          <input id="tranferenciaR" type="radio" name="radioPagos" value=1 (change)="transferencia()">
          <label for="tranferenciaR">
            Pago por Transferencia
          </label>
        </div>
        <div style="width: 100%;" *ngIf="metodoPago == 'Transferencia' && facturame2">
          <div class="part4" style="width: 100%; display: flex; justify-content: center; padding: 1em 0em;">
            <button [disabled]=" this.respuestaOV !== null || metodoPago != 'Transferencia'" class="compra-ya"
              id="comprame" (click)="relizarCompra()">Comprar</button>
          </div>
        </div>
      </div>

      <!-- <div class="opt-acord">
          <div class="up">
            <input id="webpayR" type="radio" name="radioPagos" value = 2 (change)="tarjetas()" [disabled]="facturame2 == false">
            <label for="webpayR">
              Pago con Tarjeta (Web Pay)
            </label>
          </div>
          <div style="width: 100%; display: flex; justify-content: center; padding: 1em 0em;" *ngIf="metodoPago == 'Web Pay' && facturame2">
            <button class="abirwp" (click)="handleWP()">Abrir</button>
            <div class="modal-c-wp" #modal_wp id="modal_wp">
              <div class="btn-wp-cont">
                <button class="btn-wp-close" (click)="handleWP()">Cerrar</button>
              </div>
              <iframe [src]="urlsafe" scrolling="yes" height="850px" style="width: 100%; overflow: hidden; border: 0px; display: inherit;"></iframe>
            </div>
          </div>
        </div> -->

      <div class="opt-acord" *ngIf="grandTotal>15 && grandTotal <= 10000">
        <div class="up">
          <input id="oxxoR" type="radio" name="radioPagos" value=3 (change)="oxxoPay()">
          <label for="oxxoR">
            Pago en Efectivo OXXO
          </label>
        </div>
        <div style="width: 100%;" *ngIf="metodoPago == 'Oxxo Pay' && facturame2">
          <div class="part4" style="width: 100%; display: flex; justify-content: center; padding: 1em 0em;">
            <!-- ! Se desabilita el boton, si esta otro metodo de pago habilitado -->
            <button [disabled]=" this.respuestaOV !== null || metodoPago != 'Oxxo Pay'" class="compra-ya" id="comprame"
              (click)="relizarCompra()">Comprar</button>
          </div>
        </div>
      </div>

      <div class="opt-acord">
        <div class="up">
          <input id="paypalR" type="radio" name="radioPagos" value=4 (change)="paypal()">
          <label for="paypalR">
            Pago con PayPal
          </label>
        </div>
        <div style="width: 100%; padding-top: 1em;" *ngIf="metodoPago == 'PayPal' && facturame2">
          <ng-container *ngIf="facturame2">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
  <button class="back-checkout-3" (click)="regresaPago()">Regresar</button>
  </div>

  <div class="personal" id="resumen"
    style="display: none; width: 100%; height: auto; flex-direction: column; justify-content: flex-start; align-items: center;">

    <div style="align-items: center;width: fit-content;text-align: center;">
      <div>
        <img style="width: 10em;" src="/assets/img/loguito.jpg" />
      </div>
      <label style="font-weight: 100; font-size: 1.6em">
        ¡GRACIAS POR TU COMPRA!
      </label>
      <p style="font-size: 1.2em; font-weight: 100; text-align: center; color: #ff5c6a;">
        Valoramos tu apoyo y estamos emocionados de tenerte como cliente.
      </p>
    </div>

    <div class="imgCont ion-float-start" style="padding-top: 1em; padding-bottom: 1em;">
      <h1 class="rowResumen"
        style="font-size: 2.2em; margin-block-start: 0em; margin-block-end: 0em; font-weight: 400; color: #1f99bb; text-shadow: 2px 2px 1px #71cffb; text-align: center;">
        Resumen de compras
      </h1>
    </div>

    <div
      style="width: 320px; height: auto; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
      <div
        style="width: 100%; height: auto; padding: 1em; display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 1em;"
        *ngFor="let item of productitos">
        <div style="width: 5em; height: 5em;">
          <img style="width: 100%; height: 100%;" src="{{item.image_link}}" />
        </div>
        <p style="margin-block-start: 0em; margin-block-end: 0em; width: calc(100% - 6em); font-size: 1em; font-weight: 100;"
          class="tituloItemCompra">{{item.title}}</p>
      </div>
    </div>

    <div
      style="width: 320px; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: .3em;">
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Total:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;" class="cantidadesResumen">$ {{grandTotal | number:
          '1.2-2'}} MXN</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Tipo de Pago:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{metodoPago}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;"
        *ngIf="metodoPago == 'Transferencia'">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">No. de Cuenta</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{noCuenta}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;"
        *ngIf="metodoPago == 'Transferencia'">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Clabe Interbancaria</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{clabe}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;"
        *ngIf="metodoPago == 'Transferencia'">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Banco</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{banco}}</span>
      </div>
      <div style="width: 100%; display: flex; gap: 0.5em;" *ngIf="metodoPago == 'Transferencia'">
        <span style="color: #df0b0b; font-size: .9em;">Importante: </span>
        <span style="color: #000; font-size: 1em;">Recuerda que para hacer valido tu pago es importante que nos envíes
          tu comprobante de pago al correo de
          ventas&#64;lideart.com.mx y contacto&#64;lideart.com.mx, en el asunto agregar la orden de venta.</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;"
        *ngIf="metodoPago == 'Oxxo Pay'">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Referencia</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{oxxxo}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Tipo de Envio:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{tipoEnvio}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Cotización:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{respuestaCotizacion}}</span>
      </div>
      <div class="rowResumen" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;" class="etiquetasResumen">Orden de Venta:</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{respuestaOV}}</span>
      </div>
      <div *ngIf="facturame" style="width: 100%; display: flex; justify-content: space-between;">
        <span>Datos de Facturación:</span>
      </div>
      <div *ngIf="facturame" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;">Uso CFDI</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{usocfdi}}</span>
      </div>
      <div *ngIf="facturame" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;">Razon Social</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{razonSocial}}</span>
      </div>
      <div *ngIf="facturame" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: 1em;">RFC</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{rfc}}</span>
      </div>
      <div *ngIf="facturame" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;">Dirección Fiscal</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{DirFiscal}}</span>
      </div>
      <div *ngIf="facturame" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;">Forma de Pago</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{metodoPago}}</span>
      </div>
      <div *ngIf="facturame" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;">Codigo Postal</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{codigoPostal}}</span>
      </div>
      <div *ngIf="facturame" style="width: 100%; display: flex; justify-content: space-between;">
        <span style="color: #0f95bf; font-size: .8em;">Regimen Fiscal</span>
        <span style="font-weight: 100; font-size: .9em; color: #000;">{{regimenfiscal}}</span>
      </div>
    </div>

    <div class="contB" style="padding: 1em 0em;">
      <button
        style="font-size: 1.2em; font-weight: 100; color: #ff5c6a; background-color: #fff; border: 1px solid #ff5c6a; border-radius: 3em; padding: .5em 2em;"
        [routerLink]="['/home']" class="full" routerLinkActive="router-link-active">Aceptar</button>
    </div>
  </div>

  <div class="comprassss" (click)="quitaAnimacion()" id="comprita" style="display:none;">
    <img src="/assets/img/compra.jpg">
    <button (click)="quitaAnimacion()" class="botonsillo">Ir a Resumen</button>
  </div>

  <div *ngIf="isModalOpen" class="modal-backdrop" (click)="closeModal()">
    <div class="modal-content" (click)="stopPropagation($event)">
      <h2 style="text-align: center;">Terminos de Revocación</h2>
      <p>Información requerida para proceder con devoluciones de dinero :</p>
      <p>- Monto de la devolución</p>
      <p>- Motivo de la devolución</p>
      <p>- Comprobante de pago
      </p>
      <p>- Caratula de estado de cuenta que contenga: Nombre del beneficiario de la cuenta (debe ser la misma cuenta de
        la cual procede el pago), número de cuenta, clabe interbancaria (18 dígitos) y nombre del banco en el que se
        realizará el depósito.
      </p>
      <p>- POR DISPOSICIONES FISCALES Y POLÍTICAS DE LA EMPRESA, no se efectuarán reembolsos a clientes a una cuenta
        distinta a la procedente de los recursos.
      </p>
      <p>- Su información personal se procesa y almacena en servidores o medios magnéticos que mantienen altos
        estándares de seguridad y protección tanto física como tecnológica.
      </p>
      <p>Para cualquier situación referente a faltante de material, error en el surtido o error en su pedido, cuenta
        solo con 72 horas hábiles para reportarnos la situación, la cual estará sujeta a revisión.
      </p>
      <p>Cuando devuelve un artículo, acepta que el reembolso y la forma en que se acredita pueden variar de acuerdo con
        la condición del artículo, el tiempo que ha tenido el artículo y la forma en que este fue comprado. Si devuelve
        un producto diferente o en condiciones diferentes al que enviamos, no podremos procesar su reembolso.
      </p>
      <p>El producto debe venir en su empaque original y en las mismas condiciones que le fue enviado. Al devolver el
        producto debe proteger tanto el producto como el empaque del mismo para evitar daños en transporte.
      </p>
      <p>En material cortado NO habrá devoluciones ni cambios.
      </p>
      <p>Contamos con 24 horas para realizar las revisiones.
      </p>
      <p>No hay cambios inmediatos.
      </p>
      <p>Se cuentan con 72 horas hábiles para realizar cualquier devolución.
      </p>
      <p>Todos los materiales de uso y soporte del producto (incluyendo herramientas, manuales, tarjetas de garantía,
        etc.) deben ser devueltos con el artículo.
      </p>
      <p>Códigos de licencias o tarjetas electrónicas no se pueden devolver después de la compra.
      </p>
      <p>Si la dirección de devolución está en cobertura de nuestro transportista, recibirá una etiqueta de devolución
        prepagada, de lo contrario se deberá llevar a una sucursal de paquetería y poner el paquete por cobrar.
      </p>
      <p>En caso de que se tenga que aplicar algún reembolso por medio del método de pago CONEKTA se debe de esperar un
        mínimo de 30 días hábiles.
      </p>
      <p>De acuerdo con nuestra política de devoluciones, no será posible realizar la devolución de productos que se
        encuentren en perfectas condiciones para su uso. Esto incluye productos que no presenten defectos de fabricación
        ni daños visibles, y que hayan sido correctamente manipulados y almacenados. En caso de que el producto cumpla
        con estas condiciones, no se aceptarán devoluciones, ya que se considera que está en condiciones aptas para su
        uso conforme a su propósito original.
      </p>
      <button (click)="closeModal()">Cerrar</button>
    </div>
  </div>


  <div *ngIf="isModalOpen2" class="modal-backdrop" (click)="closeModal2()">
    <div class="modal-content" (click)="stopPropagation2($event)">
      <h2 style="text-align: center;">Términos de Servicio</h2>
      <ul>
        <li>
          Si el pago es con cheque, el envío NO se hará hasta que hayan transcurrido 48 horas y se haya reflejado el
          pago.
        </li>
        <li>
          Si el método de pago se realizó por transferencia bancaria, el envío se hará hasta que hayan transcurrido 24
          horas después de haber enviado tu comprobante
          de pago.
        </li>
        <li>
          Para pago con TRANSFERENCIA ó en OXXO tiene solo 3 días para realizar su pago y enviar su comprobante al
          correo contacto&#64;lideart.com.mx y ventas&#64;lideart.com.mx
          indicando la orden de venta de su pedido que este empieza con L-OV00... en el asunto del correo, en caso de no
          recibir su pago al 4to día le llegara un correo confirmando
          la cancelación de su pedido, y en caso de aun querer el pedido será necesario volverlo a realizar.
        </li>
        <li>
          Todos los pedidos hechos en días hábiles antes de las 14:00 hrs. salen el mismo día, después de esa hora, el
          envío se hace al día siguiente hábil.
        </li>
        <li>
          Es necesario presentar su credencial de elector a la hora de recoger su mercancía en sucursal.
        </li>
        <li>
          En el caso poco probable que un equipo o mercancía saliera defectuosa, por favor contáctese al correo
          contacto&#64;lideart.com.mx y siga las instrucciones
          que le indique el colaborador de nuestra compañía.
        </li>
        <li>
          Favor de validar sus datos fiscales, no se realizarán refacturaciones por error de envío de datos.
        </li>
        <li>
          Favor de indicar al momento de su compra si requieren factura fiscal proporcionando los datos necesarios para
          tal acción, de lo contrario no se podrá
          refacturar la mercancía.
        </li>
        <li>
          Estimado Cliente, le recordamos que para poder facturar con nosotros debe estar dado de alta ante el SAT. De
          lo contrario se le realizará la venta como
          “Público en General” con los precios establecidos.
        </li>
        <li>
          En caso de que el paquete sea rechazado por parte del cliente, él mismo deberá cubrir el costo de la guía
          usado para el regreso del paquete.
        </li>


        <li>
          Las paqueterías son externas a Lideart por lo que les pedimos que si al recibir su paquete va con algún golpe,
          con sellos de nuestra cinta violados,
          cajas rotas, cajas húmedas o con algún tipo de daño que pueda comprometer o no la mercancía que va dentro les
          pedimos que por precaución, se haga la
          anotación al firmar de recibido con los comentarios del estado de la caja. Les pedimos confirmarnos dicha
          información en un lapso de no mayor a 24 hrs,
          al correo contacto&#64;lideart.com.mx adjuntando fotografías o video, para en caso de que al abrir su caja
          encuentren algún daño en su mercancía, podamos reportarlo
          a paquetería oportunamente, favor de indicar la referencia de su pedido en el asunto del correo (9 letras
          indicadas en este mismo correo).
        </li>

        <li>
          Los tiempos de entrega son estimados de 5 a 6 días hábiles según la temporada, en temporada como hot sales,
          buen fin y época decembrina los tiempos de entrega pueden ser de hasta 10 o 15 días hábiles para la entrega de
          tu paquete.
        </li>
        <li>
          De acuerdo con nuestra política de devoluciones, no será posible realizar la devolución de productos que se
          encuentren en perfectas condiciones para su uso. Esto incluye productos que no presenten defectos de
          fabricación ni daños visibles, y que hayan sido correctamente manipulados y almacenados. En caso de que el
          producto cumpla con estas condiciones, no se aceptarán devoluciones, ya que se considera que está en
          condiciones aptas para su uso conforme a su propósito original.
        </li>
      </ul>
      <button (click)="closeModal2()">Cerrar</button>
    </div>
  </div>
  <app-modal-carga icon="warning" [ttl]="120" [isLoading]="cargando" [textillo]="textoCargando"
    alertText="Lamentamos los inconvenientes que has experimentado con tu compra. Por favor, ponte en contacto con nuestro equipo de atención al cliente para asistirte y resolver cualquier problema relacionado con tu transacción. Si el dinero ha sido extraído de tu cuenta, te aseguramos que trabajaremos para solucionarlo lo antes posible."
    alertTitle="Ocurrio algo inesperado..."></app-modal-carga>
</section>