<section class="cartDesk" *ngIf="isDesk">
  <div class="nohay" *ngIf="totalItems==0">
    <h1 class="ion-text-center">No tienes artículos en el carrito</h1>
    <i class="fa-regular fa-face-sad-tear grap"></i>
    <button class="goshop" routerLink="/categoria/10/categorías-por-tipo-de-producto">Ir a Comprar</button>
  </div>
  <div class="sihay" *ngIf="totalItems>0">
    <h1 class="taitol">Carrito</h1>
    <div class="prodsDesk">
      <div class="prods-cont">
        <div class="fixin">
          <div class="prod" *ngFor="let item of items; let i = index">
            <div class="max-w-40 border border-[#c9c9c9] rounded-full overflow-hidden">
              <img src="{{item.image_link}}" alt="">
            </div>
            <div class="desc">
              <p class="neim" style="cursor: pointer;"
                (click)="navigateToDescripcionProducto(item.category_rewrite,item.id_product,item.link_rewrite,item.paquete,item.sku)">
                {{item.nombreMuestra}}</p>
              <p class="skiu">SKU: {{item.sku}}</p>
              <p class="rest">Cantidad: <input class="inpa" type="number" [value]="item.quantity"
                  (change)="validateInput($event,i)"> </p>
              <p class="rest">Color: {{item.color}}</p>
              <p class="rest">${{item.precio*item.quantity | number: '1.2-2'}}</p>
            </div>
            @if (!item.isAvaible && item.qtyDisp) {
            <div class="alerta-stock">
              @if(item.qtyDisp == 0) {
              <span>¡No hay stock!</span>
              }
              @else if (item.qtyDisp < item.quantity) { <span>¡No hay stock suficiente!</span>
                }
                @else {
                <span>¡Producto no disponible por el momento!</span>
                }
            </div>
            }
            <div class="opts-alts">
              <div class="wish">
                <p (click)="presentAlertConfirm(
                  item.id_product,
                item.title,
                item.sku,
                item.quantity
                ,item.image_link,
                item.color,
                item.medida,
                item.precio)"><ion-icon name="heart-outline"></ion-icon> Wishlist</p>

              </div>
              <div class="delete">
                <p (click)="onDelete(i)"><ion-icon name="trash-outline"></ion-icon> Eliminar</p>
              </div>
            </div>
          </div>
        </div>
        <hr style="width: 95%; height: 10px; background: rgb(0 163 197);">
        <div class="subtotal">
          <div class="articulos">
            <p>{{totalItems}} artículo(s)</p>
            <p>${{grandTotal | number: '1.2-2'}}</p>
          </div>
        </div>
      </div>


      <div class="total">

        <div class="titl" *ngIf="usuario">
          <p *ngIf="totalpuntos >=10000">Tienes Disponible canjear descuento(s) por tus Lidepuntos</p>
          <button class="btn" (click)="canjea5()" *ngIf="totalpuntos >=10000 && canjieLidepuntos == false">5%
            Descuento</button>
          <button class="btn" style="margin-left: 5px;" (click)=" canjea10()"
            *ngIf="totalpuntos >=30000 && canjieLidepuntos == false">10% Descuento</button>
          <button class="btn" style="margin-left: 5px;" (click)=" borramelcupon2()"
            *ngIf="cancelarLidepuntos == true">Cancelar</button>
          <p *ngIf="canjieLidepuntos == false">¿Tienes un código promocional?</p>
        </div>
        <div class="code" *ngIf="usuario">
          <input type="text" [(ngModel)]="codigo" name="codigo" *ngIf="canjieLidepuntos == false">
          <button class="btn" *ngIf="cancelar == false && canjieLidepuntos == false"
            (click)=" checamelcupon()">Añadir</button>
          <button class="btn" style="margin-left: 5px;" (click)=" borramelcupon()"
            *ngIf="cancelar == true">Cancelar</button>
        </div>
        <div class="tot" *ngIf="descuento == 'no'">
          <p>Total (IVA incluido)</p>
          <p>${{grandTotal | number: '1.2-2'}}</p>
        </div>
        <div class="tot" *ngIf="descuento == 'si'">
          <p *ngIf="descuento == 'si'">Total: </p>
          <p *ngIf="descuento == 'si'">${{grandTotal+calculo | number: '1.2-2'}}</p>

        </div>
        <div class="tot" *ngIf="descuento == 'si'">
          <p *ngIf="descuento == 'si'">Descuento: </p>
          <p *ngIf="descuento == 'si'">${{calculo | number: '1.2-2'}}</p>

        </div>
        <div class="tot" *ngIf="descuento == 'si'">
          <p *ngIf="descuento == 'si'">Total a Pagar: </p>
          <p *ngIf="descuento == 'si'">${{grandTotal | number: '1.2-2'}}</p>

        </div>

        <div class="bot-cont">
          <button class="btn" routerLink="/categoria/10/categorías-por-tipo-de-producto">Seguir comprando</button>
          <button [disabled]="this.shoppingCart.isLoadingCarritoData() || !this.isAllItemAvaible" *ngIf="usuario"
            class="btn" (click)="registraCarrito()">Comprar ahora</button>
          <button class="btn2" (click)="vaciaCarrito()">Vaciar Carrito</button>
          @if (!usuario) {
          <button class="btn noinic" [routerLink]="['/login']" routerLinkActive="router-link-active">
            Inicia sesión</button>
          <!-- <div id="paypal-button-container"></div> -->
          <button class="btn-rosa noinic" [routerLink]="['/checkout-invitado']"
            [disabled]="this.shoppingCart.isLoadingCarritoData()|| !this.isAllItemAvaible"
            routerLinkActive="router-link-active">Comprar
            como
            invitado</button>
          }
        </div>
      </div>
    </div>
  </div>
</section>

<div class="cont-cart-cel" *ngIf="totalItems>0 && isCel">
  <div class="cont-back-btn">
    <button (click)="goBack()" class="btn-back"><i class="fa-solid fa-left-long"></i></button>
  </div>
  <div class="prods">
    <div class="prod" *ngFor="let item of items; let i = index">
      <div class="f-l">
        <div class="img-cont">
          <img src="{{item.image_link}}" alt="">
        </div>
        <div class="desc">
          <div class="stock-message" *ngIf="!item.isAvaible && item.qtyDisp !== undefined">
            <span *ngIf="item.qtyDisp == 0" class="stock-message">¡No hay stock!</span>
            <span *ngIf="item.qtyDisp > 0 && item.qtyDisp < item.quantity" class="stock-message">¡No hay stock
              suficiente!</span>
            <span *ngIf="item.qtyDisp > 0 && item.qtyDisp >= item.quantity" class="stock-message">¡Producto no
              disponible por el momento!</span>
          </div>
          <p class="name-prod"
            (click)="navigateToDescripcionProducto(item.category_rewrite,item.id_product,item.link_rewrite,item.paquete,item.sku)">
            {{item.nombreMuestra}}</p>
          <p class="eskiu">SKU: {{item.sku}}</p>
          <!--<p>Cantidad: <input style="width: 30%; background-color: transparent; border: 1px solid #000;" type="number"  [value]="item.quantity"  (change)="validateInput($event,i)"> </p>-->
          <p class="color">Color: {{item.color}}</p>
          <p class="price">${{item.precio*item.quantity | number: '1.2-2'}} <span class="coin">MXN</span></p>
        </div>
      </div>
      <div class="opts-f-1">
        <button class="delete-btn" (click)="onDelete(i)"><i class="fa-solid fa-trash"></i>Eliminar producto(s)</button>
        <div class="cont-cant">
          <div class="cantidad-cont">
            <button class='movercant' style="border-radius: .3em 0em 0em .3em;"
              (click)="handleCantidad(-1, i)">-</button> <!-- (click)="handleCantidad(-1)" -->
            <!--<div class="cantidad">
                {{toggleCantidad}}
            </div>-->
            <input class="cantidad" inputmode="numeric" (change)="validateInput($event,i)" value="{{item.quantity}}">
            <!-- (input)="handleInputChange($event)" -->
            <button class='movercant' style="border-radius: 0em .3em .3em 0em;"
              (click)="handleCantidad(+1, i)">+</button>
          </div>
        </div>
      </div>
      <div class="opts-f-2">
        <button class="btn-wl"
          (click)="presentAlertConfirm(item.id_product, item.title, item.sku, item.quantity, item.image_link, item.color, item.medida || 0, item.precio)"><i
            class="fa-regular fa-heart"></i> Agregar a Wishlist</button>
      </div>
    </div>

    <div class="subtotal">
      <div class="articulos">
        <p class="l-p">{{totalItems}} artículo(s)</p>
        <p class="r-p">${{grandTotal | number: '1.2-2'}} <span class="coin">MXN</span></p>
      </div>
    </div>

    <div class="total">
      <div class="titl">
        <p *ngIf="totalpuntos >=10000">Tienes Disponible canjear descuento(s) por tus Lidepuntos</p>
        <button class="btn" (click)="canjea5()" *ngIf="totalpuntos >=10000 && canjieLidepuntos == false">5%
          Descuento</button>
        <button class="btn" style="margin-left: 5px;" (click)=" canjea10()"
          *ngIf="totalpuntos >=30000 && canjieLidepuntos == false">10% Descuento</button>
        <button class="btn" style="margin-left: 5px;" (click)=" borramelcupon2()"
          *ngIf="cancelarLidepuntos == true">Cancelar</button>
        <p *ngIf="canjieLidepuntos == false">¿Tienes un código promocional?</p>
      </div>
      <div class="code">
        <input type="text" [(ngModel)]="codigo" name="codigo" *ngIf="canjieLidepuntos == false">
        <button class="btn" *ngIf="cancelar == false && canjieLidepuntos == false"
          (click)=" checamelcupon()">Añadir</button>
        <button class="btn" style="margin-left: 5px;" (click)=" borramelcupon()"
          *ngIf="cancelar == true">Cancelar</button>
      </div>
      <div class="tot" *ngIf="descuento == 'no'">
        <p class="l-p">Total (IVA incluido)</p>
        <p class="r-p">${{grandTotal | number: '1.2-2'}} <span class="coin">MXN</span></p>
      </div>
      <div class="tot" *ngIf="descuento == 'si'">
        <p *ngIf="descuento == 'si'">Total: </p>
        <p *ngIf="descuento == 'si'">${{grandTotal+calculo | number: '1.2-2'}}</p>

      </div>
      <div class="tot" *ngIf="descuento == 'si'">
        <p *ngIf="descuento == 'si'">Descuento: </p>
        <p *ngIf="descuento == 'si'">${{calculo | number: '1.2-2'}}</p>

      </div>
      <div class="tot" *ngIf="descuento == 'si'">
        <p *ngIf="descuento == 'si'">Total a Pagar: </p>
        <p *ngIf="descuento == 'si'">${{grandTotal | number: '1.2-2'}}</p>

      </div>
    </div>

    <div class="bot-cont">
      <button class="btn" *ngIf="usuario" (click)="registraCarrito()" [disabled]="!isAllItemAvailable()">Proceder al
        pago</button>
      <button class="btn" routerLink="/categorias">Seguir comprando</button>
      <button *ngIf="!usuario" class="btn noinic" [routerLink]="['/login']" routerLinkActive="router-link-active">Para
        comprar, Inicia sesión</button>
      <button class="btn noinic" [routerLink]="['/checkout-invitado']"
        [disabled]="this.shoppingCart.isLoadingCarritoData()|| !this.isAllItemAvaible"
        routerLinkActive="router-link-active">Comprar como invitado</button>
      <button class="btn2" (click)="vaciaCarrito()">Vaciar Carrito</button>
      <!-- <div id="paypal-button-container"></div> -->


    </div>

  </div>
</div>

<div *ngIf="totalItems==0 && isCel">
  <h1 class="ion-text-center">No tienes artículos en el carrito</h1>
  <div style="display:flex;align-items: center;justify-content:center;">
    <ion-button class="ion-text-center" routerLink="/categoria/categorías-por-tipo-de-producto">Ir a
      Comprar</ion-button>
  </div>
</div>

<div class="mensaje-cupon" #mensaje_cupon>
  <span class="mensaje">{{mensaje}}</span>
</div>

<div class="loader" *ngIf="cargando">
  <img src="/assets/img/loadersillo.gif">
</div>