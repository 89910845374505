@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

.line-filter {
  text-align: center;
  line-height: 20px;
  background: #70c2d1;
  padding: 0;
  margin: 0; 
}

.name-client{
  font-size: 0.9em;
  max-width: 12em;
  padding: 0;
}

.swiper-button-next, .swiper-button-prev {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.swiper-container {
  position: relative;
}

.swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

ion-searchbar {
  --icon-color: #1ca3c9;
  width: 95%;
  margin-left: 10px;
}

.search-container {
  width: 90vw;
  right: 5vw;
  height: auto;
  max-height: 50vh;
  overflow-y: scroll;
  position: absolute;
}

.price-strikethrough {
  text-decoration: line-through;
  color: #ff7589;
}

menu-text{
  font-size: 2em;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon h6 {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.menuLogo {
  padding: 1em;
}

h6 {
  --color: var(--ion-text-color);
}

.loader {
  display: flex;
  width: 100%;
  height: 100%;
  background-position: center;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: fixed;
  top: calc(0%);
  left: calc(0%);
  background-color: rgb(255, 255, 255);
  opacity: 0.9;

  img{
    width: 200px;
    height: auto;

  }

}

.opts-cont-menu{
  background-color: #ffffff;
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: center;
  padding: 0em 1em;

  img{
    margin-left: 20px;
  }

  .tog-menu-btn{
    // background-color: transparent;
    i{
      color: #71d7df;
      font-size: 1.7em;
    }
  }

  /*.name-client{

  }*/

  .tog-search-bar{
    background-color: transparent;

    i{
      color: #fff;
      font-size: 1.2em;
    }
  }

  .cart-go{
    margin-left: 10px;
    font-size: 1.5em;
    color: #71d7df;
    text-decoration: none;

    .i-l{
      font-size: .5em;
      background-color: #f00;
      padding: 0.2em;
      border-radius: 4px;
      margin-left: 0.3em;
    }
  }

  .cart-go2{
    margin-left: 130px;
    font-size: 1.5em;
    color: #71d7df;
    text-decoration: none;
  }
  .cart-go3{
    margin-left: 10px;
    font-size: 1.5em;
    color: #71d7df;
    text-decoration: none;
  }

  .cart-go4{
    margin-left: 200px;
    font-size: 1.5em;
    color: #71d7df;
    text-decoration: none;
  }

  @media (max-width: 992px) {
    .cart-go2{
      margin-left: 716px;
    }
  }

  @media (max-width: 970px) {
    .cart-go2{
      margin-left: 686px;
    }
  }

  @media (max-width: 940px) {
    .cart-go2{
      margin-left: 656px;
    }
  }

  @media (max-width: 910px) {
    .cart-go2{
      margin-left: 626px;
    }
  }

  @media (max-width: 880px) {
    .cart-go2{
      margin-left: 596px;
    }
  }

  @media (max-width: 850px) {
    .cart-go2{
      margin-left: 566px;
    }
  }

  @media (max-width: 820px) {
    .cart-go2{
      margin-left: 536px;
    }
  }

  @media (max-width: 790px) {
    .cart-go2{
      margin-left: 506px;
    }
  }

  @media (max-width: 760px) {
    .cart-go2{
      margin-left: 476px;
    }
  }

  @media (max-width: 730px) {
    .cart-go2{
      margin-left: 446px;
    }
  }

  @media (max-width: 700px) {
    .cart-go2{
      margin-left: 416px;
    }
  }

  @media (max-width: 670px) {
    .cart-go2{
      margin-left: 386px;
    }
  }

  @media (max-width: 640px) {
    .cart-go2{
      margin-left: 356px;
    }
  }

  @media (max-width: 610px) {
    .cart-go2{
      margin-left: 326px;
    }
  }

  @media (max-width: 580px) {
    .cart-go2{
      margin-left: 296px;
    }
  }

  @media (max-width: 550px) {
    .cart-go2{
      margin-left: 266px;
    }
  }

  @media (max-width: 520px) {
    .cart-go2{
      margin-left: 236px;
    }
  }

  @media (max-width: 490px) {
    .cart-go2{
      margin-left: 206px;
    }
  }

  @media (max-width: 460px) {
    .cart-go2{
      margin-left: 176px;
    }
  }

  @media (max-width: 430px) {
    .cart-go2{
      margin-left: 149px;
    }
  }

  @media (max-width: 403px) {
    .cart-go2{
      margin-left: 119px;
    }
  }

  @media (max-width: 373px) {
    .cart-go2{
      margin-left: 89px;
    }
  }

  @media (max-width: 345px) {
    .cart-go2{
      margin-left: 65px;
    }
  }

  @media (max-width: 992px) {
    .cart-go4{
      margin-left: 784px;
    }
  }

  @media (max-width: 971px) {
    .cart-go4{
      margin-left: 762px;
    }
  }

  @media (max-width: 949px) {
    .cart-go4{
      margin-left: 740px;
    }
  }

  @media (max-width: 927px) {
    .cart-go4{
      margin-left: 718px;
    }
  }

  @media (max-width: 905px) {
    .cart-go4{
      margin-left: 696px;
    }
  }

  @media (max-width: 883px) {
    .cart-go4{
      margin-left: 674px;
    }
  }

  @media (max-width: 861px) {
    .cart-go4{
      margin-left: 652px;
    }
  }

  @media (max-width: 839px) {
    .cart-go4{
      margin-left: 630px;
    }
  }

  @media (max-width: 817px) {
    .cart-go4{
      margin-left: 608px;
    }
  }

  @media (max-width: 795px) {
    .cart-go4{
      margin-left: 586px;
    }
  }

  @media (max-width: 773px) {
    .cart-go4{
      margin-left: 564px;
    }
  }

  @media (max-width: 751px) {
    .cart-go4{
      margin-left: 542px;
    }
  }

  @media (max-width: 729px) {
    .cart-go4{
      margin-left: 520px;
    }
  }

  @media (max-width: 707px) {
    .cart-go4{
      margin-left: 498px;
    }
  }

  @media (max-width: 685px) {
    .cart-go4{
      margin-left: 476px;
    }
  }

  @media (max-width: 663px) {
    .cart-go4{
      margin-left: 454px;
    }
  }

  @media (max-width: 641px) {
    .cart-go4{
      margin-left: 432px;
    }
  }

  @media (max-width: 619px) {
    .cart-go4{
      margin-left: 410px;
    }
  }

  @media (max-width: 597px) {
    .cart-go4{
      margin-left: 388px;
    }
  }

  @media (max-width: 575px) {
    .cart-go4{
      margin-left: 366px;
    }
  }

  @media (max-width: 553px) {
    .cart-go4{
      margin-left: 344px;
    }
  }

  @media (max-width: 531px) {
    .cart-go4{
      margin-left: 322px;
    }
  }

  @media (max-width: 509px) {
    .cart-go4{
      margin-left: 300px;
    }
  }

  @media (max-width: 487px) {
    .cart-go4{
      margin-left: 278px;
    }
  }

  @media (max-width: 465px) {
    .cart-go4{
      margin-left: 256px;
    }
  }

  @media (max-width: 443px) {
    .cart-go4{
      margin-left: 234px;
    }
  }

  @media (max-width: 421px) {
    .cart-go4{
      margin-left: 212px;
    }
  }

  @media (max-width: 399px) {
    .cart-go4{
      margin-left: 190px;
    }
  }

  @media (max-width: 377px) {
    .cart-go4{
      margin-left: 168px;
    }
  }

  @media (max-width: 355px) {
    .cart-go4{
      margin-left: 146px;
    }
  }

  @media (max-width: 333px) {
    .cart-go4{
      margin-left: 132px;
    }
  }
}

.cel-menu{
  display: none;
}

.desk-header-app{
  display: block;
}

.desk-footer-app{
  display: block;
}

.gorgori{ // ion-header
  display: none;
}

.search_toggle{
  width: 100%;
  height: 100%;


  #axbody{
    max-height: none!important;
    min-height: 100% !important;
    list-style: none;
    overflow-y: scroll!important;
    width: 100%!important;
    height: 20em;
    padding-inline-start: 0px;
    padding: 0em 1em;

    .ui-corner-all{
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;
      border-bottom: 1px solid #00d7ff;
      border-bottom-style: dashed;
      padding-bottom: 0.75em;
      margin-bottom: 1em;
    }

    /*.ui-corner-all:last-child{
      border-bottom: none;
      margin-bottom: none;
    }*/

    .search_lewa{
      display: block;
      width: 5em;
      height: 5em;
      border: 1px solid #00d3ff;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      overflow: hidden;

      img{
        width: 100%;
        height: 100%;
      }
    }

    .search_prawa{
      width: calc(100% - 6em);
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.36em;

      .search_marka{
        color: #fff;
        font-weight: 100;
        font-size: .7em;
      }

      .search_nazwa{
        font-size: .85em;
        color: #fff;
        font-weight: 400;
      }

      .search_cena{
        font-size: .9em;
        color: #fff;
        font-weight: 100;
      }
    }
  }

  ul::-webkit-scrollbar {
    width: .35em;
    background-color: transparent;
    height: .7em;
  }
    
  ul::-webkit-scrollbar-thumb {
    background-color: #ffffffb7;
    //border-radius: 1vh;
    width: 0.25em;
  }

  #search_plugin{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    form{
      width: 70%;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;

      .buscainpcel{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        height: 80%;
        padding: 0em 1em;
        border-radius: 5px;
        background-color: transparent;
        // border: none;
        color: #fff;
        font-size: .9em;
      }

      .buscainpcel:focus{
        outline: none;
      }

      .buscainpcel::placeholder{
        color: rgba(255, 255, 255, 0.671);
        font-size: .8em;
      }
    }
  }

  .conten-abs{
    position: absolute;
    min-width: 40em;
    height: auto;
    top: 3em;
    right: 0;
    z-index: 10;
    background-color: #00a3c7;
    padding: 1em;
    border-radius: 2em;

    .cuerpoPistas{

      .txt-decir{
        margin: 0;
        margin-bottom: 0.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4em;
        font-weight: 100;
        cursor: pointer;
      }

      .ud1{
        font-size: .6em;
        margin-left: 1em;
        color: #fff;
      }

      .lasPistas{
        width: 100%;
        max-height: 8em;
        background-color: #0096b5;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: .5em;
        padding: 1em;
        overflow-y: scroll;
        border-radius: 2em 0em 0em 2em;
        transition: .2s all ease-in-out;
      }

      .lasPistas::-webkit-scrollbar {
        width: .35em;
        background-color: transparent;
        height: .7em;
      }
        
      .lasPistas::-webkit-scrollbar-thumb {
        background-color: #ffffffb7;
        //border-radius: 1vh;
        width: 0.25em;
      }

      .lasPistas div{
        width: fit-content;
        display: inline-block;
        padding: 5px 10px;
        color: #00d9ff;
        border: 1px dashed #00d3ff;
        margin: 3px;
        border-radius: 3px;
        cursor: pointer;
        transition: .6s all ease-in-out;
      }

      .lasPistas div:hover{
        color: #ffffff;
        border: 1px dashed #ffffff;
      }
    }

    .dowi{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1em;

      .lefti{
        width: 40%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.3em;

        .card{
          width: 100%;
          height: 3em;
          background-color: #0096b2;
          padding: 0.1em;
          border-radius: 0.5em;
          transition: .2s all ease-in-out;
          overflow: hidden;

          .lista{
            overflow-y: scroll;
            width: 100%;
            height: 9em;
          }

          .lista::-webkit-scrollbar {
            width: .35em;
            background-color: transparent;
            height: .7em;
          }
            
          .lista::-webkit-scrollbar-thumb {
            background-color: #ffffffb7;
            //border-radius: 1vh;
            width: 0.25em;
          }

          .lista div{
            position: relative;
            border-bottom: 1px solid #ffffff!important;
            width: 100%;
            height: 2.5em;
            display: flex!important;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            font-size: .9em;
            font-weight: 100;

            input[type='checkbox']{
              cursor: pointer;
              width: 1.2em;
              height: 1.2em;
            }
          }

          .taitl-filt{
            text-align: center;
            cursor: pointer;
            color: #fff;
            font-size: .9em;
            font-weight: 100;

            i{
              font-size: .7em;
              color: #fff;
            }
          }

          .category-tree{
            overflow-y: unset!important;
            padding: 0em 0em;
            margin-block-start: 0em;
            margin-block-end: 0em;
            height: auto;

            .less{
              height: auto!important;

              div{
                border-bottom: none;
              }

              .w-100{
                overflow-y: unset!important;
              }
            }
          }
        }
      }

      .rigti{
        width: 60%;
        height: auto;
        height: 25em;
        background-color: #00ade1;
        border-radius: 1em;

        .result-taitl{
          color: #fff;
          font-size: 1em;
          font-weight: 100;
        }
      }
    }
  }
}

@media(max-width: 992px){
  .cel-menu{
    position: fixed;
    left: -100%;
    top: 0;
    width: 80%;
    height: 100%;
    background-color: #ffffff;
    z-index: 100;
    transition: .3s all ease-in-out;
    //padding: 2em 2em;
    display: block;

    .rest-close{
      width: 25%;
      height: 100%;
      position: absolute;
      background-color: rgb(0 0 0 / 30%);
      top: 0;
      right: -25%;
    }

    .closemen{
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      color: #fff;
      font-size: 1.8em;
    }

    .list-cont-opcs{
      //border: 1px solid red;
      width: 100%;
      height: calc(100% - 2.5em);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-y: scroll;
      padding-top: env(safe-area-inset-top);

      .porta-cel-cont{
        width: 100%;
        height: auto;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .log-cont{
          width: 100%;
          height: auto;
          padding: 1em;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          .imag{
            width: 12em;
            height: auto;
          }
        }
      }  

      .blue-zone{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: rgb(57, 184, 235);
        padding: 1em 0em;

        .opt-blue-zone{
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 3em;
          padding: 0.7em 0em;
          padding-left: 3em;
          text-decoration: none;

          .icona-opt{
            color: #fff;
            font-size: .9em;
            width: 1.4em;
          }

          .txt-opt-bz{
            color: #fff;
            font-size: .8em;
          }
        }
      }

      .rest-opts-cont{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #fff;
        padding: 1em 0em;

        .opt-rest-zone{
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 3em;
          padding: 0.7em 0em;
          padding-left: 1.5em;
          text-decoration: none;

          .icona-opt{
            color: rgb(57, 184, 235);
            font-size: 1.3em;
            width: 1.2em;        
          }

          .txt-opt-r{
            color: rgb(57, 184, 235);
            font-size: .8em;
          }
        }
      }
    }

    .list-cont-opcs::-webkit-scrollbar {
      width: .1em;
      background-color: transparent;
      height: .7em;
    }
   
    .list-cont-opcs::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 1vh;
      width: 0.25em;
    }
  }

  .desk-header-app{
    display: none;
  }

  .desk-footer-app{
    display: none;
  }

  .gorgori{
    display: block;
  }

  .desk-header{
    display: none;
  }

  .footer-desk{
    display: none;
  }

  .cel-toggle{
    /*position: absolute;
    top: calc(3.5em);*/ 

    .form-busc-cont{


      width: 100%;
      height: 100%;
      border-radius: 3em;
      position: relative;
      //border: 1px solid red;
    
      .btn-busc{
        
        position: absolute;
        font-size: .8em;
        font-weight: 100;
        padding: .4em 1em;
        border-radius: 1em;
        background-color: #52d3ff;
        color: #fff;
        right: .5em;
        top: .5em;
        z-index: 1;
      }

      .ssd{
        
        position: absolute;
        font-size: .8em;
        font-weight: 100;
        padding: .4em 1em;
        border-radius: 1em;
        color: #fff;
        right: .5em;
        top: .5em;
        z-index: 1;
      }
    
      .buscainp{
     
        width: 100%;
        height: 2em;
        color: #fff;
        background-color: transparent;
        padding-left: 1em;
        padding-right: 5em;
        border: none;
        background-color: #0083ab;
      }
    
      .buscainp:focus{
        outline: none;
      }
    
      .buscainp::placeholder{
        color: #e6e6e6;
        font-weight: 100;
        font-size: .8em;
      }
    }

    .conten-abs-cel{
      min-width: 0em;
      width: 100%;
      top: 2.2em;
      border-radius: 0em;
      height: calc(100vh - 5.4em);
      overflow-y: scroll;
      background-color: #00c1f9;

      .dowi{
        flex-direction: column;

        .lefti{
          width: 100%;
        }

        .rigti{
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
      }
    }

    #search_plugin{ 
      form{
        .buscainpcel{
          background-color: #0083ab;
          border-radius: 0em 0em 0em 0em;
          height: 2.5em;
        }
      }
    }
  }

  .results-cont-google{
    position: absolute;
    top: 2em;
    right: 0;
    width: 100%;
    height: calc(100vh - 6em);
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    background-color: #fff;
    z-index: 99;
    border-radius: 1em;

    .clearing{
      border: 1px solid #dd0000;
      color: #dd0000;
      background-color: transparent;
      padding: .5em 2em;
      border-radius: 2em;
    }

    .links-cont-results{
      width: 100%;
      height: 85%;
      //border: 1px solid red;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1em;
      padding: 1em 1em;

      .prod-cont{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none ;
        box-shadow: 0px 0px 10px 1px #e3e3e3;
        border-radius: .75em;
        padding: 1em;
        gap: .5em;

        .pp{
          width: 60%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: .5em;
          color: #fff;
          border-radius: 3em;
          padding: .3em 0em;

          i{
              font-size: .9em;
          }

          .txt-pp{
              font-weight: 100;
              font-size: .9em;
          }
        }

        .pp-cat{
          background-color: #fb7388;
        }

        .pp-prod{
          background-color: #60b9e6;
        }

        .pp-paq{
          background-color: #b098c7;
        }

        .info-prod{
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 1em;

          .img-cont-prod{
            width: 6em;
            height: 6em;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid #e7e7e7;

            .img-prod{
              width: 100%;
              height: 100%;
            }
          }

          .txt-cont{
            width: calc(100% - 7em);
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 1em;

            .title-prod{
              color: #000;
              line-height: 1em;
              font-weight: 100;
              font-size: .9em;                
            }

            .line-tb{
              // padding: 1.5em 0.5em;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              margin: .5em;
        
              .r-color{
                // position: absolute;
                margin: 0.5px;
                width: 20px;
                height: 20px;
                border: 1px solid #cfcfcf;
                border-radius: 50%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                cursor: default;
                // right: 1em;
                // top: 0.5em;
              }
        
              .r-color.hidden {
                display: none;
              }
        
              .r-color.more-colors {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                border: 1px solid #cfcfcf;
                border-radius: 50%;
                background-color: #f0f0f0;
                margin: 0.1em;
                font-weight: bold;
                font-size: 14px;

                  &:hover {
                      background-color: #ddd;
                  }
                  ::after {
                      content: "↓";
                  }
                  .prod-cont .r-color.more-colors.up-arrow::after {
                      content: "↑"; /* El símbolo de la flecha hacia arriba */
                    }
              }
            }

            .price-cont-prod{
              width: 100%;

              .price-prod{
                  color: #000;
                  font-weight: 100;
                  font-size: 1.1em;
                  transition: .4s all ease-in-out;

                  .curr{
                      font-weight: 500;
                      font-size: .7em;
                  }
              }
            }
          }
        }
      }

      .prod-cont:hover{

      }
    }

    .btns-cont{
      width: 100%;
      height: 15%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1em;
      cursor: pointer;

      .prev-forw-btns{
        background-color: #00a0c6;
        color: #fff;
        width: 7em;
        height: 2.5em;
        border-radius: 2em;
        transition: .4s all ease-in-out;
        border: 1px solid #00a0c6;
      }

      .prev-forw-btns:hover{
        background-color: #ffffff;
        color: #00a0c6;
      }
    }

    .links-cont-results::-webkit-scrollbar {
      width: .4em;
      background-color: rgb(255, 255, 255);
      height: .7em;
    }
      
    .links-cont-results::-webkit-scrollbar-thumb {
      background-color: #0096b2;
      //border-radius: 1vh;
      width: 0.25em;
    }
  }
}

@media(min-width: 992px){
  .asideMenu{
    display: none!important;
    position: relative!important;
    z-index: -1!important;
    visibility: hidden!important;
  }
}